import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const currencyApi = createApi({
    tagTypes: ['Currencies'],
    reducerPath: "currencies",
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({

        // GET ALL CURRENCIES
        getAllCurrencies: builder.query({
            query: (pageno) => `api/admin/currencies?pageno=${pageno}`,
            transformResponse: (res) => res,
            providesTags: ['Currencies'],
        }),

        getCurrencyById: builder.query({
        }),
        query: (id) => `api/admin/currency/${id}`,

        // CREATE NEW CURRENCY
        addNewCurrency: builder.mutation({
            query: (newCurrency) => ({
                url: `api/admin/currency`,
                method: "POST",
                body: newCurrency,
            }),
        }),
        // UPDATE CURRENCY
        UpdateCurrency: builder.mutation({
            query: ({ id, from, to, value }) => ({
                url: `api/admin/currency/edit/${id}`,
                method: "PUT",
                body: {
                    from: from,
                    to: to,
                    value: value
                },
            }),
            invalidatesTags: ['Currencies']
        }),
        // TOGGLE CURRENCY STATUS
        updateCurrencyStatus: builder.mutation({
            query: (id) => ({
                url: `api/admin/currency/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ['Currencies']
        }),

        deleteCurrency: builder.mutation({
            query: (id) => ({
                url: `api/admin/delete/currency/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['Currencies']
        }),
        
    }),
});

export const {
    useAddNewCurrencyMutation,
    useGetCurrencyByIdQuery,
    useGetAllCurrenciesQuery,
    useUpdateCurrencyStatusMutation,
    useUpdateCurrencyMutation,
    useDeleteCurrencyMutation,
} = currencyApi;