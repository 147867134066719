import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import AddExchangeRateModal from 'src/components/modals/AddExchangeRateModal';
import { useDeleteCurrencyMutation, useUpdateCurrencyStatusMutation } from 'src/store/Reducer/currency';
import { enqueueSnackbar } from 'notistack';
import { Tooltip } from 'recharts';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Icon, MenuItem } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { LoadingButton } from '@mui/lab';

export default function UserTableRow({ row, selected, setOpen, setIsEdit, setSelectedCurrency, counter, refetch }) {
    const { from, status, to, value } = row;

    const confirm = useBoolean()
    const popover = usePopover();
    const [deleteCurrency ,{isLoading}] = useDeleteCurrencyMutation();

    const [updateCurrencyStatus] = useUpdateCurrencyStatusMutation();
    const [currencyStatus, setCurrencyStatus] = useState(status);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const storedStatus = localStorage.getItem(`currencyStatus_${row._id}`);
        if (storedStatus !== null) {
            setCurrencyStatus(JSON.parse(storedStatus));
        }
    }, [row._id]);

    const handleUpdateCurrencyStatus = async (id) => {
        const newStatus = !currencyStatus;
        try {
            await updateCurrencyStatus(id);
            setCurrencyStatus(newStatus);
            enqueueSnackbar('Status updated successfully!');
            // Update the status in the row state
            localStorage.setItem(`currencyStatus_${id}`, JSON.stringify(newStatus));
            // Refetch the table data
            refetch();
        } catch (error) {
            console.error('Failed to update currency status!', error);
            enqueueSnackbar('Failed to update currency status', { variant: 'error' });
        }
    }

    const handleEdit = (currency) => {
        setSelectedCurrency(currency);
        setIsEdit(true);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsEdit(false);
        setSelectedCurrency(null);
    }

    const handleDelete = async () => {
        const { error , data} = await deleteCurrency(row._id);
        
        if (!error) {
          confirm.onFalse()
          popover.onClose()
          enqueueSnackbar( data?.message ||'Deleted Successfully')
        }
      }

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell>{counter}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{from}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{to}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{value}</TableCell>
                <TableCell>
                    <Label variant="soft" color={(currencyStatus === true && 'success') || (currencyStatus === false && 'error') || 'warning'}>
                        <Button variant='text' size='small' onClick={() => handleUpdateCurrencyStatus(row?._id)}>
                            {currencyStatus ? "Active" : 'Inactive'}
                        </Button>
                    </Label>
                </TableCell>
                <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton onClick={() => handleEdit(row)}>
                        <Iconify icon="solar:pen-bold" />
                    </IconButton>
                    <IconButton  onClick={popover.onOpen}>
                          <Iconify icon="mdi:delete" />
                    </IconButton>
                 
                </TableCell>
            </TableRow>
            <AddExchangeRateModal 
                open={openModal} 
                handleClose={handleCloseModal} 
                setOpen={setOpenModal} 
                isEdit={true} 
                selectedCurrency={row} 
                refetch={refetch} 
                reset={() => {}} 
            />

       <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
        //   <Button variant="contained" color="error" onClick={handleDelete} >
        //     Delete
        //   </Button>
            <LoadingButton color="error" variant="contained" loading={isLoading} onClick={handleDelete}>
            Delete
          </LoadingButton>
        }
      />
        </>
    );
}

UserTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
    counter: PropTypes.number,
    setOpen: PropTypes.func.isRequired,
    setIsEdit: PropTypes.func.isRequired,
    setSelectedCurrency: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
};
