import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const customersApi = createApi({
  reducerPath: "customers",
  tagTypes: ['Customers'],
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      query: ({ pageno, search }) => ({
        url: `api/admin/customers?pageno=${pageno}&search=${search}`,
        method: "GET",
        transformResponse: (res) => res,
      }),
      providesTags: ['Customers']
    }),

    getAllDeletedCustomers: builder.query({
      query: ({ pageno, search }) => ({
        url: `api/admin/customers/account/deleted?pageno=${pageno}&search=${search}`,
        method: "GET",
        transformResponse: (res) => res,
      }),
      providesTags: ['Customers']
    }),

    updateCustomerStatus: builder.mutation({
      query: (id) => ({
        url: `api/admin/customer/status/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ['Customers']
    }),

    restoreCustomer: builder.mutation({
      query: (id) => ({
        url: `api/admin/customer/account/restore/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ['Customers']
    }),

    updateCustomerApprovalStatus: builder.mutation({
      query: (id) => ({
        url: `api/admin/customer/account/approve/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    getCustomerById: builder.query({
      query: (id) => `api/admin/customer/${id}`,
    }),

    addNewCustomer: builder.mutation({
      query: (newCustomer) => ({
        url: `api/admin/customer`,
        method: "POST",
        body: newCustomer,
      }),
    }),

    updateCustomer: builder.mutation({
      query: (updatedCustomer) => ({
        url: `api/admin/customer/edit`,
        method: "PUT",
        body: updatedCustomer,
      }),
      invalidatesTags: ['Customers']
    }),

    customerWithdraw: builder.mutation({
      query: (data) => ({
        url: `api/admin/customer/balance/topup_withdrawal`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['Customers']
    }),

    customerTopUp: builder.mutation({
      query: (data) => ({
        url: `api/admin/customer/balance/topup_withdrawal`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['Customers']
    }),

    customerAccToAcc: builder.mutation({
      query: (data) => ({
        url: `api/admin/customer/balance/topup_withdrawal`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['Customers']
    }),

    getCustomerTransactions: builder.query({
      query: ({id, pageno}) => ({
        url: `api/admin/customer/transactions/history/${id}?pageno=${pageno}`,
        method: "GET",
        transformResponse: (res) => res,
        providesTags: ['Customers']
      }),
    }),

    getRecieversList: builder.query({
      query: ({id, pageno, search}) => ({
        url: `api/admin/customer/recievers/list/${id}?pageno=${pageno}&search=${search}`,
        method: "GET",
        transformResponse: (res) => res,
        providesTags: ['Customers']
      }),
    }),

    getAccountStatement: builder.query({
      query: ({id, start_date, end_date}) => ({
        url: `api/admin/account/statement?customer_id=${id}&start_date=${start_date}&end_date=${end_date}`,
        method: "GET",
        transformResponse: (res) => res,
        providesTags: ['Customers']
      }),
    }),
  }),
});

export const {
  useGetAllCustomersQuery,
  useGetAllDeletedCustomersQuery,
  useGetCustomerByIdQuery,
  useAddNewCustomerMutation,
  useUpdateCustomerMutation,
  useUpdateCustomerStatusMutation,
  useUpdateCustomerApprovalStatusMutation,
  useRestoreCustomerMutation,
  useCustomerWithdrawMutation,
  useCustomerTopUpMutation,
  useCustomerAccToAccMutation,
  useGetCustomerTransactionsQuery,
  useGetRecieversListQuery,
  useLazyGetAccountStatementQuery
} = customersApi;
